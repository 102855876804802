html {
	-webkit-text-size-adjust: 100%;
}
  
body {
	margin:0;
	padding:0;
}

html, body {
	overflow: auto !important;
}

a {
	outline: 0;
	text-decoration: none;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	cursor: pointer;
}

img {
	outline : none;
}

b,
strong {
	font-weight: 800;
}

p {
	margin: 0;
}

* {
	text-rendering: optimizeLegibility !important;
	-webkit-font-smoothing: antialiased !important;
	-moz-osx-font-smoothing: grayscale !important;
}

.hide,
.hidden {
	display: none !important;
}